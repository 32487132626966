.career-path h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 3vw, 64px);
  font-weight: bold;
  position: relative;
}
.career-path .circle-spacing {
  padding: 0px 7px;
  margin: 0px -8px;
  line-height: clamp(18px, 1vw, 42px);
}
