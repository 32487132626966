.customButton button {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #080808F2;
  border: none;
  border-radius: 14px !important;
  padding: 18px 45px !important;
  font-weight: 500;
  transition: all 0.5s ease;
  white-space: nowrap;
  width: fit-content !important;
}
.customButton button:hover {
  scale: 1.05;
  box-shadow: 12px 12px 30px 0px rgb(179, 179, 179);
}
@media screen and (max-width: 1024px) {
  .customButton button {
    padding: 12px 24px !important;
    border-radius: 8px !important;
  }
}
