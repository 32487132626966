.platform {
  background-color: white;
  display: flex;
  flex-direction: column;
}
.platform h1 {
  font-size: clamp(26px, 3.2vw, 70px);
  line-height: clamp(26px, 3.2vw, 70px);
  color: #080808;
}
.platform h4 {
  font-size: clamp(16px, 1.4vw, 28px);
  font-weight: 400;
  font-family: "DMSans-Regular";
}
.platform p,
.platform li {
  font-family: "DMSans-Regular";
  font-weight: 500;
  color: rgb(70, 70, 70);
}
.lightning-section {
  /* background-image: linear-gradient(to top, #ffffff, #fff8f2, #fff8f2, #FFE9D9); */
  background-color: #ffe9d9;
  position: relative;
  overflow: hidden;
}
.lightning-section img {
  position: relative;
  z-index: 20;
}
.lightning-section .content-section {
  position: relative;
  z-index: 20;
}
.lightning-section .bg-Img {
  position: absolute;
  z-index: 10;
  opacity: 1;
  left: 0%;
  top: -35%;
  max-width: 80%;
  mix-blend-mode: screen;
}
.ul-section span {
  font-weight: 700;
}

.lightning-section .circle-spacing {
  padding: 0px 7px;
  margin: 0px -7px;
  line-height: clamp(18px, 1vw, 50px);
}

@media screen and (max-width: 1200px) {
  .lightning-section .circle-spacing {
    padding: 0px 3px;
    margin: 0px -3px;
  }
}

@media screen and (max-width: 991px) {
  .lightning-section .bg-Img {
    position: absolute;
    z-index: 10;
    opacity: 1;
    left: 0%;
    top: -15%;
    width: 100%;
    max-width: 700px;
    mix-blend-mode: luminosity;
  }
}
@media screen and (max-width: 640px) {
  .lightning-section .bg-Img {
    position: absolute;
    z-index: 10;
    opacity: 1;
    left: 0%;
    top: -5%;
    width: 120%;
    max-width: 700px;
    mix-blend-mode: luminosity;
  }
}
