.AIExperties .cardBox {
  border-radius: 15px;
  background-color: #f3f3f3;
  padding: 40px 50px;
  transition: ease all 0.5s;
  cursor: pointer;
  min-height: 100%;
}
.AIExperties h1 {
  font-size: clamp(26px, 4vw, 72px);
  line-height: clamp(26px, 3vw, 60px);
  font-weight: bold;
  position: relative;
  color: #000000f1;
}
.AIExperties h3 {
  font-size: clamp(20px, 1vw, 54px);
  line-height: clamp(20px, 1vw, 46px);
  color: #fd6100;
}
.AIExperties h4 {
  font-family: "Syne-Bold";
  font-size: clamp(16px, 1vw, 22px);
}
.AIExperties p {
  font-family: "DMSans-Regular";
}
.AIExperties .cardBox:hover {
  border-radius: 15px;
  background-color: white;
  box-shadow: #08080826 0px 7px 29px 0px;
  /* border: 1px solid #f3f3f3; */
}

.AIExperties .icon-div {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background-color: white;
  transition: ease all 0.5s;
}
.AIExperties .cardBox:hover .icon-div {
  background-color: #f3f3f3 !important;
}
.AIExperties .circle-spacing {
  padding: 0px 5px;
  margin: 0px -5px;
  line-height: clamp(18px, 1vw, 42px);
}
