.clientsExperience {
  background-color: white;
}

.clientsExperience .slider button {
  background-color: #a1a1a1;
  color: white;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.clientsExperience .slider button:hover {
  background-color: black;
}
.clientsExperience .icon {
  font-size: clamp(32px, 10vw, 150px);
  font-family: "Rockstar-ExtraBold";
  color: #fd6100;
}
.clientsExperience .btnmove {
  position: absolute;
  top: 60%;
  width: 100%;
  /* margin-top: 50%; */
}
