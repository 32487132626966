body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
p {
  color: #08080899;
}
@font-face {
  font-family: "Syne-Bold";
  src: local("Syne-Bold"),
    url("./assets/fonts/Syne-Bold/Syne-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "DMSans-Regular";
  src: local("DMSans-Regular"),
    url("./assets/fonts/DMSans/DMSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rockstar-ExtraBold";
  src: local("Rockstar-ExtraBold"),
    url("./assets/fonts/Rockstar-ExtraBold/Rockstar-ExtraBold.otf") format("truetype");
}
