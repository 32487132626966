.careers {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.careers .bg-linear {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
}
.careers .textcolor {
  color: #fd6100;
}
.careers .h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(28px, 2.8vw, 60px);
  font-weight: bold;
  position: relative;
  color: #000000f1;
}
.careers h3 {
  color: rgba(43, 43, 43, 0.664);
  font-size: clamp(16px, 1.2vw, 24px);
  font-family: "DMSans-Regular";
}

.grid-container {
  display: grid;
  align-items: end;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  gap: 5px;
}
@media screen and (max-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}
@media screen and (max-width: 724px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
@media screen and (max-width: 912px) {
  .careers {
    gap: 20px;
  }
}
