.corevalues {
  /* background-color: #f9f8f6; */
  background: linear-gradient(180deg, #f9f8f6 20%, #fff 80%);
}

.corevalues .box {
  height: 480px;
  overflow-y: auto;
  scrollbar-width: none;
}

.corevalues .box::-webkit-scrollbar {
  display: none;
}
.corevalues .innerbox {
  border-radius: 7px;
  background-color: white;
}
.corevalues .innerbox img {
  width: 130px;
}
.corevalues h5 {
  font-weight: 600;
}
