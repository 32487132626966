.herobanner {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
  padding-top: 3%;
}
.herobanner h1 {
  font-size: clamp(32px, 6vw, 110px);
  line-height: clamp(32px, 6vw, 110px);
  color: #080808;
}
.herobanner img {
  width: 85%;
}

.herobanner .desc {
  font-family: "DMSans-Regular";
  font-size: clamp(16px, 0.95vw, 26px);
  font-weight: 500;
  color: rgb(88, 88, 88);
}
.herobanner p {
  font-family: "DMSans-Regular";
  color: rgb(88, 88, 88);
}
.circle-spacing {
  padding: 0px 5px;
  margin: 0px -5px;
  line-height: clamp(22px, 3vw, 90px);
}
