.ai-banner * {
  padding: 0px;
  margin: 0px;
}
.ai-banner {
  position: relative;
  overflow: hidden;
  background-color: #0e0e0e;
  color: white;
  padding: 100px 0px;
}
.ai-banner .content-section {
  position: relative;
  z-index: 10;
}
.ai-banner h1 {
  font-weight: 600;
  font-size: clamp(32px, 8vw, 110px);
  line-height: clamp(26px, 7vw, 85px);
}
.ai-banner p {
  font-size: clamp(16px, 0.8vw, 24px);
  color: white;
  font-family: "DMSans-Regular";
}
.ai-banner .video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  mix-blend-mode: luminosity;

  /* background-color: red; */
}
.ai-banner .video-section img {
  opacity: 0.05;
  mix-blend-mode: overlay;
  display: none;
}
.ai-banner .video-section video {
  object-fit: cover;
}
.ai-banner .grid-img {
  mix-blend-mode: overlay;
}
@media screen and (max-width: 991px) {
  .ai-banner .video-section video {
    display: none;
  }
  .ai-banner .video-section img {
    opacity: 0.01;
    mix-blend-mode: overlay;
    display:block;
  }
}
