.InfoCards {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
  color: black;
  padding: 70px 0px;
}

.InfoCards img {
  width: 100%;
}
/* .InfoCards .mainbox {
  min-height: 370px;
} */
.InfoCards .griditems {
  font-weight: 700;
  cursor: pointer;
  transition: ease all 0.5s;
}
.InfoCards .griditems:hover {
  transform: translateY(30px);
  margin-top: 10px;
}
@media only screen and (max-width: 1240px) {
  .InfoCards {
    padding: 40px 0px;
  }
}
@media only screen and (max-width: 992px) {
  .InfoCards {
    padding: 40px 0px;
  }
  .InfoCards .mainbox {
    min-height: fit-content;
  }
}
.InfoCards h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 3vw, 64px);
  font-weight: bold;
  position: relative;
}
.InfoCards h5 {
  color: #fd6100;
}
.InfoCards p {
  margin-top: 20px;
  color: black;
  font-size: clamp(16px, 1.2vw, 24px);
}
.InfoCards .circle-spacing {
  padding: 0px 6px;
  margin: 0px -6px;
  line-height: clamp(18px, 1vw, 42px);
}
