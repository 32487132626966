.solutionOverview {
  background-image: white;
}
.solutionOverview .main-section {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
  padding: 5% 0px;
}

.solutionOverview h1 {
  font-size: clamp(26px, 3.2vw, 70px);
  line-height: clamp(26px, 3.2vw, 70px);
  color: #080808;
}

.solutionOverview h4 {
  font-size: clamp(16px, 1.4vw, 28px);
  font-weight: 400;
  font-family: "DMSans-Regular";
}
.solutionOverview p,
.solutionOverview li {
  font-family: "DMSans-Regular";
  font-weight: 500;
  color: rgb(70, 70, 70);
}
.solutionOverview .circle-spacing {
  padding: 0px 7px;
  margin: 0px -7px;
  line-height: clamp(18px, 1vw, 50px);
}
@media screen and (max-width: 1200px) {
  .solutionOverview .circle-spacing {
    padding: 0px 3px;
    margin: 0px -3px;
  }
}
