.banner-center-heading h1 {
  font-weight: 600;
  font-size: clamp(32px, 9vw, 110px);
  line-height: clamp(32px, 9vw, 110px);
}
.banner-center-heading p {
  color: rgba(43, 43, 43, 0.664);
  font-size: clamp(16px, 1.2vw, 24px);
  font-family: "DMSans-Regular";
}
