.explorePage h1,
.explorePage h2,
.explorePage h3,
.explorePage h5 {
  font-family: "Syne-Bold" !important;
}
.explorePage h4,
.explorePage p,
.explorePage li {
  font-family: "DMSans-Regular";
}

.explorePage h1 {
  font-weight: 600;
  font-size: clamp(32px, 3.2vw, 80px);
  line-height: clamp(34px, 4vw, 85px);
}
.explorePage h2 {
  font-weight: 600;
  font-size: clamp(26px, 2vw, 68px);
}
.explorePage p,
.explorePage li {
  font-size: clamp(16px, 1vw, 26px);
  line-height: clamp(22px, 4vw, 32px);
  color: #08080899;
}
.explorePage h4 {
  font-weight: 500;
  font-size: clamp(20px, 1.3vw, 34px);
  line-height: clamp(20px, 2vw, 32px);
}

.explorePage h5 {
  font-size: clamp(16px, 1.1vw, 28px);
  line-height: clamp(22px, 2vw, 32px);
}

.explorePage li::marker {
  color: #fd6100;
}

@media screen and (max-width: 640px) {
  .technology-section img {
    width: 100px;
  }
}
