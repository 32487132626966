.OurExpertise {
  background-color: #ffe9d9;
  padding: 70px 0px;
}
.OurExpertise .card {
  background-color: white;
  border-radius: 20px;
  padding: 4% 3%;
  border: none;
  transition: all 0.5s ease;
  cursor: pointer;
}
.OurExpertise .card:hover {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  scale: 1.02;
}
.OurExpertise h5 {
  color: #ff9330;
}
.OurExpertise h5 span {
  font-family: "Syne-Bold";
}
.OurExpertise .card p {
  color: #08080862;
}
.OurExpertise h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 3vw, 64px);
  font-weight: bold;
  position: relative;
}
.OurExpertise h4 {
  color: gray;
  font-size: clamp(16px, 1.2vw, 30px);
  font-weight: 400;
  line-height: clamp(20px, 2.2vw, 32px);
}
.OurExpertise h3 {
  font-family: "Syne-Bold";
}
.OurExpertise button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
}
.OurExpertise .circle-spacing {
  padding: 0px 7px;
  margin: 0px -7px;
  line-height: clamp(18px, 1vw, 42px);
}
@media screen and (max-width: 768px) {
  .OurExpertise .card {
    padding: 5%;
  }
}
