.ourValues {
  background-color: white;
  padding-top: 50px;
  margin-bottom: 15%;
}
.ourValues .maincontent {
  background-color: #ffe9d9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.ourValues .video {
  z-index: 30;
  position: relative;
  margin-bottom: -15%;
}

@media screen and (max-width: 900px) {
  .ourValues {
    padding-top: 0px;
    margin-bottom: 8%;
  }
}
