/* NavigationBar.css */

.navigation-bar {
  position: fixed;
  z-index: 50;
  width: 100%;
}

.navigation-bar .navigation-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.5s ease;
}

.navigation-bar .logo-container {
  padding-left: 4vw;
  cursor: pointer;
}

.navigation-bar .logo-image {
  transition: opacity 2s ease-in-out;
  opacity: 1;
  position: absolute;
  top: 15px;
  left: 4vw;
}

.navigation-bar .logo-image.hidden {
  opacity: 0;
  pointer-events: none;
}

.navigation-bar .nav-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 2s ease;
}
.navigation-bar .nav-actions .icon {
  font-size: 28px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.navigation-bar .nav-button {
  background-color: black;
  padding: 30px 40px;
  border: none;
}

.navigation-bar .full-screen-menu {
  position: fixed;
  z-index: 60;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.92);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(5px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 0.5s ease;
}

.navigation-bar .full-screen-menu.fade-in {
  opacity: 1;
  visibility: visible;
}

.navigation-bar .full-screen-menu.fade-out {
  opacity: 0;
  visibility: hidden;
}

.navigation-bar .menu-header {
  display: flex;
  justify-content: end;
  border-bottom: 1px solid gray;
  width: 100%;
  padding-right: calc(0px + var(--scrollbar-width));
}
.navigation-bar .menu-header button {
  background-color: #fd6100;
  outline: none;
  border: none;
  padding: 30px 40px;
}
.navigation-bar .menu-content {
  height: calc(100% - 100px);
  overflow-y: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-bar .nav-link {
  display: flex;
  justify-content: center;
  gap: 15px;
  color: white;
  margin-bottom: 10px;
  text-decoration: none;
  font-size: 32px;
}
.navigation-bar .nav-link p {
  color: white;
  cursor: pointer;
}
.navigation-bar .nav-link .icon {
  color: gray;
  margin-top: 8px;
}
.navigation-bar .social-icons {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-top: 40px;
}
@media screen and (max-width: 900px) {
  .navigation-bar .logo-image {
    width: 100px;
    top: 5px;
    left: 4vw;
  }
  .navigation-bar .nav-button {
    background-color: black;
    padding: 10px 20px;
    border: none;
  }

  .navigation-bar .nav-actions {
    gap: 10px;
  }
  .navigation-bar .nav-actions h5 {
    font-size: 18px;
    margin-top: 5px;
  }
  .navigation-bar .nav-actions .icon {
    font-size: 22px;
  }
  .navigation-bar .menu-header button {
    padding: 10px 20px;
  }

  .navigation-bar .nav-link {
    font-size: 24px;
  }
}
