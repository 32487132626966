.ContactForm {
  background-color: white;
  padding: 70px 0px;
}
.ContactForm h6 {
  color: #fd6100;
}
.ContactForm input {
  border: none;
  outline: none;
  border-bottom: 2px solid gray;
  padding-left: 5%;
  padding-bottom: 15px;
  width: 100%;
}
.ContactForm .para {
  color: #08080899;
  font: 600;
  font-size: clamp(16px, 1.2vw, 24px);
}
.ContactForm button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  width: fit-content;
}
.ContactForm h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 3vw, 64px);
  font-weight: bold;
  position: relative;
}
.ContactForm h4 {
  font-size: clamp(16px, 1.2vw, 24px);
  font-weight: 500;
  position: relative;
}
.ContactForm h5 {
  color: #fd6100;
}
.ContactForm p {
  margin-top: 20px;
  color: black;
  font-size: clamp(16px, 1.2vw, 24px);
}

.ContactForm input::placeholder {
  color: gray;
  font-size: clamp(16px, 1vw, 20px);
  font-weight: 500;
}

.ContactForm .circle-spacing {
  padding: 0px 7px;
  margin: 0px -7px;
  line-height: clamp(18px, 1vw, 50px);
}
@media screen and (max-width: 1200px) {
  .ContactForm .circle-spacing {
    padding: 0px 3px;
    margin: 0px -3px;
  }
}

@media screen and (max-width: 900px) {
  .ContactForm {
    padding: 40px 0px;
  }
}
