.feedback {
  background-color: #f3f3f3;
}
.feedback .mainsection {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.feedback .subheading {
  color: #ff9330;
}

.feedback h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(18px, 2vw, 56px);
  font-weight: bold;
  position: relative;
}
.feedback h2 {
  font-size: clamp(16px, 1.6vw, 36px);
}
.feedback h5 {
  color: #080808cc;
}
.feedback .author {
  font-weight: 900;
  color: #080808;
}
.feedback p {
  color: #080808cc;
}
.feedback .slider-section {
  width: 90%;
}
.feedback button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 50%;
  aspect-ratio: 1/1 !important;
  padding: 8px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback .circle-spacing {
  padding: 0px 7px;
  margin: 0px -7px;
  line-height: clamp(18px, 1vw, 50px);
}
@media screen and (max-width: 1200px) {
  .feedback .circle-spacing {
    padding: 0px 3px;
    margin: 0px -3px;
  }
}
@media screen and (max-width: 900px) {
  .feedback .mainsection {
    gap: 30px;
  }
}
