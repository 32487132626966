.SeeMore {
  background-color: #f9f8f6;
}
.SeeMore h3 {
  color: #ababab;
  font-weight: 500;
  font-size: clamp(16px, 2vw, 32px);
  transition: all 1s ease;
  transform: translateY(20px);
}
.SeeMore h1 {
  font-size: clamp(26px, 3vw, 64px);
  transition: all 1s ease;
  transform: translateY(20px);
}
.SeeMore h4 {
  font-size: clamp(16px, 1.3vw, 24px);
  font-weight: 500;
  color: gray;
  transform: translateY(150px);
  transition: all 1s ease;
}
.SeeMore .icon {
  cursor: pointer;
  color: lightgray;
  font-size: clamp(50px, 5vw, 120px);
  line-height: 0px;
  margin-top: 20px;
  transform: rotate(90deg);
  transition: all 1s ease;
}

.learn-more-card {
  height: 300px;
  overflow: hidden;
  padding: 0px 4vw;
  cursor: pointer;
}
.learn-more-card:hover {
  background-color: white;
}
.learn-more-card:hover h1 {
  transform: translateY(-30px);
}
.learn-more-card:hover h4 {
  transform: translateY(0px);
}
.learn-more-card:hover h3 {
  transform: translateY(-150px);
}
.learn-more-card:hover .icon {
  transform: translate(60px, 70px) rotate(90deg);
  color: black;
}

@media screen and (max-width: 1300px) {
  .SeeMore h3 {
    font-size: 20px;
  }
  .SeeMore h1 {
    font-size: 38px;
  }
  .SeeMore h4 {
    font-size: 18px;
  }
  .learn-more-card {
    height: 220px;
  }
  .learn-more-card:hover .icon {
    transform: translate(40px, 60px) rotate(90deg);
    color: black;
  }
}
@media screen and (max-width: 700px) {
  .SeeMore h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1199px) {
  .SeeMore .learn-more-inner {
    width: 360px;
  }
  .SeeMore .learn-more-card h3 {
    transform: translateY(-150px);
  }
  .SeeMore .learn-more-card h1 {
    transform: translateY(-30px);
  }
  .SeeMore .learn-more-card h4 {
    transform: translateY(0px);
  }

  .SeeMore .icon {
    cursor: pointer;
    color: black;
    font-size: clamp(50px, 5vw, 120px);
    line-height: 0px;
    margin-top: 20px;
    transform: translate(10px, 30px) rotate(90deg);
    transition: all 1s ease;
  }
}
@media screen and (max-width: 400px) {
  .SeeMore .learn-more-inner {
    width: 300px;
  }
  .SeeMore .learn-more-card h4 {
   font-size: 14px;
    transform: translateY(0px);
  }
}
