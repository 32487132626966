.SpecialtiesSection {
  padding-top: 100px;
  position: relative;
  /* background-image: url("../assets/images/SpecialtiesBg.svg");
  background-position: center;
  background-size: cover; */
  /* background-repeat: no-repeat; */
  /* height: 95vh; */
}

.SpecialtiesSection .bg-img {
  position: absolute;
  top: 0px;
  right: 0px;
}

.SpecialtiesSection .main-div {
  position: relative;
  z-index: 10;
}

.SpecialtiesSection h5 {
  color: #ff9330;
}

.SpecialtiesSection h5 span {
  font-family: "Syne-Bold";
}

.SpecialtiesSection .Box .icon-div {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background-color: white;
  transition: ease all 0.5s;
}

.SpecialtiesSection h4 {
  font-family: "Syne-Bold";
  font-size: clamp(16px, 1vw, 22px);
}

.SpecialtiesSection .Box {
  width: 80%;
}

.SpecialtiesSection .cardBox {
  border-radius: 15px;
  background-color: #f3f3f3;
  padding: 3%;
  width: 31%;
  transition: ease all 0.5s;
  cursor: pointer;
}

.SpecialtiesSection .cardBox2 h3 {
  font-size: 22px;
  color: lightgray;
}

.SpecialtiesSection .cardBox:hover {
  border-radius: 15px;
  background-color: white;
  box-shadow: #08080826 0px 7px 29px 0px;
  /* border: 1px solid #f3f3f3; */
}

.SpecialtiesSection .cardBox:hover .icon-div {
  background-color: #f3f3f3 !important;
}

.SpecialtiesSection .cardBox2 {
  /* background-image: url("../assets/images/SpecialtiesImage.svg"); */
  position: relative;
  background-color: black;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  padding: clamp(10px, 2%, 40px);
  width: 25%;
  cursor: pointer;
  transition: ease all 0.5s;
}
.SpecialtiesSection .cardBox2 .bg-img {
  position: absolute;
  top: 0px;
  right: 0px !important;
  width: 100%;
  opacity: 0.1;
}

.SpecialtiesSection .cardBox2:hover {
  border-radius: 15px;
  box-shadow: #08080869 0px 7px 29px 0px;
  scale: 1.01;
}

.AboutCompany {
  background-color: white;
  padding-top: 60px;
}

.AboutCompany h6 {
  color: #fd6100;
}

.AboutCompany button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
}

.AboutCompany .para {
  color: #08080899;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .SpecialtiesSection {
    height: fit-content;
    padding-top: 50px;
  }
  .SpecialtiesSection .cardBox {
    width: 48%;
  }
  .SpecialtiesSection .Box {
    width: 100%;
  }
  .SpecialtiesSection .cardBox2 {
    width: 100%;
  }

  .SpecialtiesSection .cardBox2 .bg-img {
    position: absolute;
    right: 0px !important;
    width: fit-content;
    min-width: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .SpecialtiesSection .cardBox {
    width: 100%;
  }
  .SpecialtiesSection .cardBox {
    padding: 12px;
  }
}

.SpecialtiesSection h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 3vw, 64px);
  font-weight: bold;
  position: relative;
}

.first-letter {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.first-letter::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  background-color: #fd6100;
  border-radius: 50%;
  z-index: -10;
}

.SpecialtiesSection .circle-spacing {
  padding: 0px 6px;
  margin: 0px -6px;
  line-height: clamp(18px, 1vw, 42px);
}
