.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-orange-circle {
  width: fit-content;
}
.bg-orange-circle span {
  border-radius: 50%;
  height: fit-content !important;
  background-color: #fd6100;
  position: relative;
  z-index: 0;
  display: inline-block;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
}

/* .characterbackground2 .main-circle {
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  aspect-ratio: 1/1 !important;
  border-radius: 50%;
  background-color: #fd6100;
  position: relative;
  z-index: 0;
}
.characterbackground2 .reamining-heading {
  position: relative;
  z-index: 10;
} */
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffe9d9;
  /* background-color: white; */
}


/* Width of the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track (the background) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #FF9330;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #FD6100;
}


.characterbackground {
  width: fit-content;
  display: flex;
  align-items: center;
}
.characterbackground span {
  position: relative;
  z-index: 1;
}
.characterbackground div {
  border-radius: 50%;
  height: fit-content !important;
  background-color: #fd6100;
  position: relative;
  z-index: 0;
  display: inline-block;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: end;
  text-align: end;
}
.augmentation {
  background-color: #ffe9d9;
}
.custombtn button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
}

.ourProcessOperation {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
}
.dataandAI {
  background-color: #ffe9d9;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  max-height: 120px;
  margin-block: 30px 50px;
}

.marquee {
  display: flex;
  animation: scroll 20s linear infinite;
}

.marquee-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.logo {
  flex: 0 0 auto;
  margin: 0 20px;
}

img {
  max-height: 100%;
  display: block;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
.custom-container {
  padding: 0px 6.5vw !important;
  margin: 0px auto;
  max-width: 2200px;
}
.spacing-div {
  padding-top: 90px;
}

.font-face-Syne-Bold,
h1 {
  font-family: "Syne-Bold" !important;
}
.font-face-DMSans-Regular {
  font-family: "DMSans-Regular";
}

.custom-h4 {
  font-size: clamp(16px, 1.4vw, 28px);
  font-weight: 400;
  font-family: "DMSans-Regular";
}
.custom-p,
.custom-li {
  font-family: "DMSans-Regular";
  font-weight: 500;
  color: rgb(70, 70, 70);
}

@media screen and (max-width: 900px) {
  .spacing-div {
    padding-top: 50px;
  }
}

.slick-next,
.slick-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.slick-next {
  right: 10px;
}

.slick-prev {
  left: 10px;
}

.EnhancedBusiness {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
}

.augmentation .title-inner {
  border-bottom: 1px solid rgb(73, 73, 73);
  padding: 10px 0px;
}

.augmentation .title-inner {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
}

.show-upto {
  display: inline;
}
.hide-upto {
  display: none;
}
@media screen and (max-width: 355px) {
  .show-upto {
    display: none;
  }
  .hide-upto {
    display: inline;
  }
}
