.banner {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: black;
  color: black;
}

.banner-bg-img {
  position: absolute;
  scale: 1.01;
  opacity: 1;
  z-index: 30;
  top: 0px;
  right: 0px;
  min-width: 100vw;
  min-height: 100vh;
  object-fit: cover;
  transition: all 2s ease;
}

.banner-bg-video {
  position: absolute;
  z-index: 20;
  bottom: 0px;
  left: 40%;
  min-width: 100%;
  min-height: 100vh;
  transition: all 2s ease;
}

.banner2-content {
  position: relative;
  padding-top: 100px;
  z-index: 40;
  min-height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10%;
  transition: all 2s ease-in-out;
}

.banner2-content .line {
  width: 40px;
  height: 1px;
  background-color: #000000;
  transition: all 2s ease !important;
}

.overlay {
  position: absolute;
  z-index: 40;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 50%;
}

.overlay:hover ~ .banner-bg-img {
  opacity: 0;
  z-index: 30;
  right: 100%;
  top: 100%;
  scale: 3;
}

.overlay:hover ~ .banner-bg-video {
  left: 0px;
}

.banner .banner2-content h1 {
  font-weight: 600;
  font-size: clamp(32px, 9vw, 110px);
  line-height: clamp(32px, 9vw, 110px);
}
.banner .circle-spacing {
  padding: 0px 6px;
  margin: 0px -6px;
  line-height: clamp(26px, 3vw, 70px);
  background-color: #FD6100;
}

.banner .banner2-content h5 {
  font-size: clamp(14px, 2vw, 24px);
  font-weight: 600;
}

.banner .banner2-content h2 {
  font-weight: 600;
  font-size: clamp(20px, 3vw, 50px);
}

.banner .banner2-content h3 {
  font-weight: 700;
  font-size: clamp(20px, 2vw, 40px);
}

.buttons {
  margin-top: 20px;
}

.banner2-content .buttons button {
  font-weight: 600;
  border-radius: 10px;
  padding: 12px 26px;
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  white-space: nowrap;
  transition: all 2s ease-in-out;
}

.banner2-content .buttons button .icon {
  font-size: 28px;
}

.banner2-content .buttons button:nth-child(1) {
  background-color: black;
  color: white;
  border: 1px solid black;
}

.banner2-content .buttons button:nth-child(2) {
  background-color: transparent;
  color: black;
  border: 1px solid rgb(175, 158, 158);
}

.banner2-content .banner-client {
  padding-top: 10%;
}

.overlay:hover ~ .banner2-content {
  color: white !important;
  transition: all 2s ease;
}
.overlay:hover ~ .banner2-content a {
  color: white !important;
}

.overlay:hover ~ .banner2-content .line {
  background-color: white;
}

.overlay:hover ~ .banner2-content .buttons button:nth-child(1) {
  background-color: #e4461f;
  border: 1px solid #e4461f;
}

.overlay:hover ~ .banner2-content .buttons button:nth-child(2) {
  background-color: white !important;
  border: 1px solid white;
}

.scrollactionwhite {
  opacity: 0;
  transition: all 2s ease-in-out;
}
.scrollaction {
  transition: all 2s ease-in-out;
}
.banner2-content a {
  text-decoration: none;
  color: black;
  transition: all 2s ease;
}

.overlay:hover ~ .banner2-content .scrollaction {
  opacity: 0;
}
.overlay:hover ~ .banner2-content .scrollactionwhite {
  opacity: 1;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.scroll-down-animation {
  animation: bounce 2s infinite;
}

@media screen and (max-width: 1024px) {
  .overlay {
    display: none;
  }
  .banner {
    height: fit-content;
    padding: 10vw 0px;
  }
  .banner-bg-img {
    display: none;
  }
  .banner-bg-video {
    left: 0px;
    object-fit: cover;
    background-color: white;
  }
  .banner2-content {
    width: 100%;
    color: white !important;
    padding-top: 50px;
    padding-left: 6%;
  }
  .banner2-content .buttons button:nth-child(1) {
    background-color: #e4461f !important;
    padding: 1.5% 4%;
    width: fit-content;
    border: 1px solid #e4461f;
  }
  .banner2-content .buttons button:nth-child(2) {
    color: white !important;
    padding: 1.5% 4%;
    width: fit-content;
    border: 1px solid white;
  }
  .banner .banner2-content h6 {
    font-weight: 400;
  }
  .banner2-content .line {
    background-color: #fff;
  }
  .banner2-content .banner-client {
    padding-top: 5%;
  }
  .banner2-content a {
    color: white !important;
  }
  .scrollaction {
    display: none;
  }
}
