.aboutus {
  position: relative;
  overflow: hidden !important;
  max-width: 100vw !important;
}
.aboutus .bg-linear {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
}
.aboutus .overlay-img {
  position: absolute;
  top: -3%;
  right: 0px;
  width: 35%;
}
.aboutus p {
  color: #08080899;
}
.aboutus .second-section h1 {
  font-size: 72px;
  line-height: 72px;
  font-weight: 700;
}
.aboutus button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  width: fit-content;
}
.aboutus .last-section .textgray1 {
  font-size: clamp(52px, 4.5vw, 90px);
  border-bottom: 1px solid lightgray;
  padding: 0px;
  margin-bottom: 15px;
  font-weight: 500;
  color: #d4d0cc;
}
.aboutus .last-section .textgray {
  font-size: clamp(32px, 3vw, 64px);
  border-bottom: 1px solid lightgray;
  padding: 0px;
  margin-bottom: 15px;
  font-weight: 500;
  color: #d4d0cc;
}
.aboutus .last-section h6 {
  color: #fd6100;
  font-weight: 400;
}
.aboutus h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 2.8vw, 60px);
  font-weight: bold;
  position: relative;
  color: #000000f1;
}
.aboutus h3 {
  font-size: clamp(18px, 1.6vw, 32px);
  line-height: clamp(22px, 2vw, 40px);
  font-weight: 500;
}
.aboutus p {
  color: rgba(43, 43, 43, 0.664);
  font-size: clamp(16px, 1.2vw, 24px);
  font-family: "DMSans-Regular";
}
.aboutus li {
  color: rgb(29, 29, 29);
  font-size: clamp(16px, 1.2vw, 24px);
  font-family: "DMSans-Regular";
}
.aboutus .box {
  overflow: hidden;
  position: relative;
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  transition: all 0.5s ease;
}
.aboutus .box h2 {
  font-size: clamp(18px, 1.4vw, 32px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  transition: all 0.8s ease;
}
.aboutus .box span {
  transform: translateY(200px);
  text-align: left;
  position: absolute;
  opacity: 0;
  transition: all 0.8s ease;
  padding: 5% 8%;
}
.aboutus .box:hover {
  border-color: #fd6100;
}
.aboutus .box:hover h2 {
  transform: translate(0%, 0%);
  top: 17%;
  left: 8%;
}
.aboutus .box:hover span {
  transform: translateY(16%);
  opacity: 1;
}
.aboutus .circle-spacing {
  padding: 0px 7px;
  margin: 0px -7px;
  line-height: clamp(18px, 1vw, 42px);
}
@media screen and (max-width: 1024px) {
  .aboutus .overlay-img {
    position: absolute;
    top: -3%;
    right: 0px;
    width: 55%;
  }
}
@media screen and (max-width: 910px) {
  .aboutus .overlay-img {
    position: absolute;
    top: -3%;
    right: 0px;
    width: 65%;
  }
}
@media screen and (max-width: 400px) {
  .aboutus .box:hover h2 {
    transform: translate(0%, 0%);
    top: 10%;
    left: 8%;
  }
  .aboutus .box:hover span {
    transform: translateY(10%);
    opacity: 1;
  }
}
