.services {
  background-color: white;
}
.services .service-header p {
  font-size: clamp(14px, 0.9vw, 20px);
  font-weight: 500;
}
.services .box {
  background-color: #f3f3f3;
  border-radius: 15px;
  transition: all 0.5s ease;
}
.services .box:hover {
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.services h1 {
  font-size: clamp(26px, 4vw, 72px);
  line-height: clamp(26px, 3vw, 60px);
  font-weight: bold;
  position: relative;
  color: #000000f1;
}
.services .box h2 {
  font-size: clamp(16px, 1.8vw, 36px);
}
.services .box p {
  padding-top: 15px;
  font-size: clamp(14px, 0.9vw, 20px);
  font-weight: 400;
  color: rgba(102, 102, 102, 0.952);
}
.services .box {
  padding: 8% 9% 5% 9%;
}
.services .circle-spacing {
  padding: 0px 5px;
  margin: 0px -5px;
  line-height: clamp(18px, 1vw, 42px);
}
@media screen and (max-width: 768px) {
  .services .box {
    height: auto;
    padding: 35px;
  }
}
.services p {
  font-family: "DMSans-Regular";
  color: rgb(88, 88, 88);
}
