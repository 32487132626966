.aboutpeople {
  position: relative;
  overflow: hidden;
  padding: 50px 0px;
}
.aboutpeople h3 {
  font-weight: 500;
  color: rgba(22, 22, 22, 0.911);
  line-height: clamp(22px, 2.2vw, 42px);
}
.aboutpeople .img-section {
  position: relative;
  display: flex;
}
.aboutpeople .img-section .img {
  position: relative;
  z-index: 10;
}
.aboutpeople .img-section .circle-img {
  position: absolute;
  width: 130%;
  aspect-ratio: 1/1 !important;
  object-fit: cover;
}
