.customlinetext {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ff9330;
}
.customlinetext .line {
  width: 40px;
  height: 1px;
  margin-bottom: 8px;
  background-color: #fd6100;
  transition: all 2s ease;
}
