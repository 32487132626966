.Footermain-div {
  background: #141313;
  color: white;
  padding: 70px 0px;
}
.Footermain-last-div {
  background: #141313;
  color: #999999;
  border-top: 1px solid #99999942;
}
.Footermain-div .column {
  gap: 1vw;
  width: 100%;
}
.Footermain-div .column1 {
  flex: 1 1 calc(40% - 20px);
  min-width: 200px;
  padding-right: 10vw;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Footermain-div .column1 span {
  color: #fd6100;
}
.Footermain-div .column1 button {
  width: fit-content;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
}
.Footermain-div .column2 {
  flex: 1 1 calc(20% - 20px);
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  white-space: nowwrape;
}
.Footermain-div h2 {
  font-family: "Syne-Bold";
}
.Footermain-div .column2 .p1:hover {
  color: white;
}
.Footermain-div .p1 {
  width: fit-content;
  color: #999999;
  padding: 0px;
  margin: 0px;
}
.Footermain-div a {
  text-decoration: none;
  color: white;
}
@media only screen and (max-width: 1024px) {
  .Footermain-div .column2 {
    flex: 1 1 calc(33.33% - 20px);
  }
  .Footermain-div .column1 {
    width: 48%;
  }
  .Footermain-div .column {
    gap: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .Footermain-div .column2 {
    flex: 1 1 100%;
  }
  .Footermain-div .column1 {
    flex: 1 1 100%;
  }
}
