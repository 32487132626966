.Contact-Us {
  padding-bottom: 50px;
}
.Contact-Us .box {
  background-image: linear-gradient(to top, #ffffff, #fff8f2);
}

.Contact-Us h4 {
  font-size: clamp(16px, 1.2vw, 24px) !important;
  font-weight: 500;
  position: relative;
}
.Contact-Us h6 {
  color: #fd6100;
}
.Contact-Us input::placeholder {
  font-size: 18px;
}
.Contact-Us input {
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(156, 156, 156);
  padding-left: 15px;
  padding-bottom: 10px;
  background-color: transparent;
  width: 100%;
  outline: none;
}
.Contact-Us option {
  background-color: white !important;
  color: #fd6100;
  padding: 5px 20% !important;
  border-bottom: 1px solid black;
}
.Contact-Us option:focus {
  background-color: #d9d9d9 !important;
  color: red;
}
.Contact-Us select {
  color: gray;
}
.Contact-Us .para {
  color: #08080899;
  font-size: clamp(16px, 1.2vw, 24px);
}
.Contact-Us button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  width: fit-content;
}
.Contact-Us .h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(28px, 2.8vw, 60px);
  font-weight: bold;
  position: relative;
  color: #000000f1;
}
.custom-dropdown {
  cursor: pointer;
  background-color: transparent;
  color: #6b6b6b;
  border: none;
  border-bottom: 2px solid rgb(100, 100, 100) !important;
  position: relative;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
  color: #6b6b6b;
  list-style: none;
  padding: 0;
  margin: 10px;
  padding: 15px 20px;
  border-radius: 50px !important;
  overflow: hidden;
}

.custom-dropdown-menu .dropdown-item {
  padding: 10px 20px;
  color: #6b6b6b;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.custom-dropdown-menu .dropdown-item:hover {
  background-color: #d9d9d94d;
}

.dropdown-icon {
  font-size: 14px;
  color: #646464;
}
