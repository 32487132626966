.dataandAI h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(26px, 2.8vw, 60px);
  font-weight: bold;
  position: relative;
  color: #000000f1;
}
.dataandAI p {
  font-size: clamp(16px, 0.8vw, 24px);
  font-family: "DMSans-Regular";
}
/* .dataandAI .img-section {
} */
.dataandAI .vector {
  width: 80%;
}
.dataandAI .circle-spacing {
  padding: 0px 5px;
  margin: 0px -5px;
  line-height: clamp(18px, 1vw, 42px);
}
