.team button {
  color: white;
  background-color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
}

.team h1 {
  font-size: clamp(26px, 3vw, 64px);
  line-height: clamp(20px, 1vw, 52px);
  font-weight: bold;
  position: relative;
}
.team h2 {
  color: #080808;
  font-size: clamp(24px, 1.5vw, 42px);
  position: relative;
  z-index: 10;
}
.team h4 {
  font-size: clamp(16px, 1.3vw, 30px);
  font-weight: 500;
  font-family: "DMSans-Regular";
}
.team h5 {
  font-size: clamp(16px, 1.1vw, 26px);
  font-weight: 500;
  font-family: "DMSans-Regular";
}
.team p,
.team li {
  font-family: "DMSans-Regular";
  font-weight: 500;
  color: rgb(70, 70, 70);
  font-size: 18px;
}
.team .card {
  position: relative;
  border: 2px solid #189cd8;
  border-radius: 15px;
  overflow: hidden;
  min-height: 100px;
  padding: 85px 10%;
}

.team .card-img {
  width: 50%;
  max-width: 150px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.ready {
  background-color: #f3f3f3;
}
.ready .ready-inner {
  background-color: #f3f3f3;
  width: 90%;
  border-radius: 15px;
}

.ready h1 {
  font-size: clamp(26px, 2vw, 42px);
  font-weight: bold;
  position: relative;
}
